import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import "./styles.module.css";
import UserService from "../services/user.service";

const DayOff = ({appointment, onHide, onAppointmentChange}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSettingDayOff, setIsSettingDayOff] = useState(false);
  const [note, setNote] = useState("");
  const [sameDayAppointments, setSameDayAppointments] = useState(null);

  useEffect(() => {
    if(isSettingDayOff) {
      getSameDate();
    }
  }, [isSettingDayOff]);

  const getSameDate = async () => {
    const appointmentDate = moment(appointment.startDateTime);
    try {
      const sameDayAppointments = await UserService.getAppointments(
        appointmentDate.startOf('day').toISOString().replace("Z", ""), 
        appointmentDate.endOf('day').toISOString().replace("Z", ""),
        "draft"
      );
      setSameDayAppointments(sameDayAppointments.data.data);
    } catch (error) {
      alert(error.response);
    }
  };
 
  const setDayOff = async values => {
    setIsSubmitting(true);
    try {
      await UserService.setDayOff(appointment.id, {"note": note, "daysOff": values.offMeetings});
      setTimeout(() => {
        alert("Day Off set");
        onAppointmentChange();
        onHide();
      }, 400);
    } catch (error) {
      alert(error.response);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openCloseForm = () => {
    setIsSettingDayOff(!isSettingDayOff);
  }

  const DayOffButton = () => (
    <div style={stylingObject.buttonsDiv}>
      <Button
        style={stylingObject.buttonRight}
        variant="warning"
        type="info"
        onClick={openCloseForm}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <span>Day Off</span>
        )}
      </Button>
    </div>
  );

  const getHoursLabel = (appointment) => {
    return moment(appointment.startDateTime).format("h a");
  }

  const DayOffHours = ({handleChange, values}) => {
    return (
      <Form.Group controlId="offMeetings" style={stylingObject.dayOffCheckbuttonsRow}>
        {sameDayAppointments && sameDayAppointments.map(appointment => 
          <Form.Check 
            key={appointment.id}
            id={appointment.id}
            type='checkbox'
            label={getHoursLabel(appointment)}
            name='offMeetings'
            defaultChecked={values.offMeetings.includes(appointment.id)}
            value={appointment.id}
            onChange={handleChange}
            style={stylingObject.dayOffCheckbutton}
          />
        )}
      </Form.Group>
    )
  }

  const DayOffForm = () => (
    <Formik
      onSubmit={setDayOff}
      initialValues={{
        note:"",
        offMeetings: sameDayAppointments ? sameDayAppointments.map(appointment => appointment.id) : [],
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        isValid,
        isSubmitting
      }) => (
        <Form
          style={stylingObject.form}
          onSubmit={handleSubmit}
        >
          <h6 style={stylingObject.labels}>Add Day Off note</h6>
          <Form.Control
            type="text"
            name="note"
            onChange={e => setNote(e.target.value)}
            value={note}
            autoFocus
            style={stylingObject.input}
          />
          <DayOffHours handleChange={handleChange} values={values} />
          <Button
            type="submit"
            style={stylingObject.buttonCenter}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Confirm Day Off</span>
            )}
          </Button>
        </Form>
          )}
    </Formik>
  );

  return (
    <>
      <DayOffButton />
      {isSettingDayOff && (<DayOffForm/>)}
    </>
  );
};

const stylingObject = {
  form: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    backgroundColor: "#d5d8db",
    borderRadius: 10,
    marginTop: ".4rem",
    marginBottom: "1rem",
  },
  buttonsDiv: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
  },
  input: {
    padding: 5,
    marginBottom: ".4rem",
  },
  labels: {
    textAlign: "center"
  },
  buttonCenter: {},
  buttonRight: {
    marginLeft: "auto",
  },
  dayOffCheckbuttonsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
  },
  dayOffCheckbutton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 30,
    paddingLeft: 30,
  },
};

export default DayOff;
