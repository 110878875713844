import React, { useState } from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Appointment from "./Appointment";
import UserService from "../services/user.service";

const AppointmentsList = () => {
  const [showAppointment, setShowAppointment] = useState(false);
  const [clientName, setClientName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [appointments, setAppointments] = useState(undefined);
  const [currentAppointment, setCurrentAppointment] = useState(undefined);
  const search = async () => {
    try {
      setIsDisabled(true);
      const result = await UserService.getAppointmentsByClient(clientName);
      setAppointments(result.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisabled(false);
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "draft":
        return "New";
      case "added":
        return "Added";
      case "confirmed":
        return "Confirmed";
      case "paid":
        return "Paid";
      case "done":
        return "Done";
      case "client_not_show_up":
        return "Client not show up";
      case "canceled_rob":
        return "Canceled by RDS";
      case "canceled_client":
        return "Canceled by Client";
      default:
        return "Appointment";
    }
  };

  const hideModal = () => {
    setShowAppointment(false);
  };

  const openAppointment = (appointment) => {
    setCurrentAppointment(appointment);
    setShowAppointment(true);
  };

  const onAppointmentChanged = () => {
    // do nothing
  };

  return (
    <>
      <div className="container">
        <header className="jumbotron">
          <h3>List of appointments</h3>
        </header>
        <div style={stylingObject.searchContainer}>
          <Form>
            <Form.Group className="mb-3" controlId="clientName">
              <Form.Label>Client name or phone number</Form.Label>
              <Form.Control
                type="input"
                placeholder="Enter name or phone number"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                disabled={isDisabled}
              />
            </Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="primary"
                style={{ minWidth: 200 }}
                onClick={search}
                disabled={isDisabled}
              >
                {isDisabled ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Search</span>
                )}
              </Button>
            </div>
          </Form>
          {appointments ? (
            <Table striped style={{ marginTop: 30 }}>
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Phone</th>
                  <th>Appointment Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {appointments.map((appointment) => {
                  return (
                    <tr
                      key={appointment.id}
                      data={appointment}
                      onClick={() => openAppointment(appointment)}
                    >
                      <td>{appointment.client.clientName}</td>
                      <td>{appointment.client.phoneNumber}</td>
                      <td>{moment(appointment.startDateTime).format("LLL")}</td>
                      <td>{getStatus(appointment.status)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p></p>
          )}
        </div>
      </div>
      <Appointment
        show={showAppointment}
        onHide={hideModal}
        appointment={currentAppointment}
        onAppointmentChange={onAppointmentChanged}
      />
    </>
  );
};

export default AppointmentsList;

const stylingObject = {
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
  },
};
