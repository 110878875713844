import axios from "axios";
import { setUser, removeUser, getUser } from "./token.service";

// const API_URL = "http://patryk85.chickenkiller.com:31014/api/v1.0.0/";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://robsdyno.malejkoit.com/api/v1.0.0/"
    : "http://localhost:8000/api/v1.0.0/";

class Auth {
  async login(username, password) {
    const response = await axios
      .post(API_URL + "token/", {
        username,
        password,
      });
    if (response.data.data.access) {
      setUser(response.data.data);
    }
    return response.data;
  }

  logout() {
    removeUser();
  }

  getCurrentUser() {
    return getUser();
  }
}

const AuthService = new Auth();

export default AuthService;
