import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ClientService from "../services/client.service";

const ClientConfirmation = (props) => {
  const { id } = useParams();
  const [status, setStatus] = useState(null);
  const [appointmentData, setAppointmentData] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);

  const ErrorLabel = () => {
    if (error) {
      if (error === "wrong status") {
        return (
          <div>
            Appointment in wrong status, did you already confirm it? If not,
            please contact us
          </div>
        );
      } else if (error === "already used") {
        return <div>Token to confirm appointment was already used</div>;
      } else if (error === "not found") {
        return <div>Appointment not found, please contact us</div>;
      } else {
        return <div>An error occured: {error}</div>;
      }
    } else {
      return <div>System is checking your confirmation</div>;
    }
  };

  const confirm = async (token) => {
    try {
      const data = {
        token: token,
      };
      const response = await ClientService.confirmAppointment(id, data);
      if (response.status === 200) {
        setStatus("paid");
      } else {
      }
    } catch (error) {
      setStatus("error");
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data["errors"]["nonFieldErrors"][0]) {
            setError(error.response.data["errors"]["nonFieldErrors"][0]);
          }
        } else {
          console.log(error.response.data);
        }
      }
    }
  };

  const loadCollectJs = () => {
    window.CollectJS.configure({
      variant: "lightbox",
      buttonText: "Pay $100 to confirm appointment",
      instructionText: "Enter Card Info Below",
      price: "100.00",
      currency: "USD",
      country: "US",
      callback: (token) => {
        setIsSubmitting(true);
        confirm(token.token);
      },
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.async = false;
    script.src =
      "https://retrieveronline.transactiongateway.com/token/Collect.js";
    script.setAttribute("data-tokenization-key", "8HbNXB-U8f9e2-7GhkR8-P3K892");
    document.head.appendChild(script);
    script.onload = loadCollectJs;
    const getAppointment = async () => {
      try {
        const response = await ClientService.getAppointment(id);
        if (response.status === 200) {
          setStatus("nonpaid");
          setAppointmentData(response.data.data);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data["errors"]["nonFieldErrors"][0]) {
              setError(error.response.data["errors"]["nonFieldErrors"][0]);
            }
          } else {
            console.log(error.response.data);
          }
        }
      }
    };
    getAppointment();
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    window.CollectJS.startPaymentRequest();
  };

  const InnerForm = () => {
    if (status === "nonpaid") {
      return (
        <div style={stylingObject.center}>
          <div style={stylingObject.center}>
            Dear {appointmentData.appointmentClient}
          </div>
          <div>
            Your appointment is set to: <br />
            <b>{moment(appointmentData.appointmentDate).format("LLL")}</b>
          </div>
          <div style={stylingObject.payButton}>
            <Button
              type="submit"
              variant="danger"
              onClick={handleSubmit}
              color={"red"}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Pay to Confirm Appointment</span>
              )}
            </Button>
          </div>
        </div>
      );
    } else if (status === "paid") {
      return (
        <div>
          <div>Dear {appointmentData.appointmentClient}</div>
          <div>
            Thank you. Your appointment is confirmed to <br />
            <b>{moment(appointmentData.appointmentDate).format("LLL")}</b>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <ErrorLabel />
        </div>
      );
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container" style={stylingObject.center}>
        <img
          src="https://lirp.cdn-website.com/a85f75f8/dms3rep/multi/opt/LayoutsDB67747D-C218-0AC4-C1B8-2184CDD25E0F.png_400-356w.png"
          alt="profile-img"
          className="profile-img-card"
          width={200}
        />
        <InnerForm />
      </div>
    </div>
  );
};

const stylingObject = {
  center: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  payButton: {
    padding: 10,
  },
};

export default ClientConfirmation;
