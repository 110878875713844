import axios from "axios";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://robsdyno.malejkoit.com/api/v1.0.0/"
    : "http://localhost:8000/api/v1.0.0/";

class ClientService {
  getAppointment(id) {
    return axios.get(API_URL + `confirm/${id}/`);
  }

  confirmAppointment(id, data) {
    return axios.post(API_URL + `confirm/${id}/`, data);
  }

  checkCancelAppointment(id) {
    return axios.get(API_URL + `cancel/${id}/`);
  }

  cancelAppointment(id) {
    return axios.post(API_URL + `cancel/${id}/`);
  }
}

export default new ClientService();
