import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "./styles.module.css";
import UserService from "../services/user.service";

const DayOffRemove = ({appointment, onHide, onAppointmentChange}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSettingDayOff, setIsSettingDayOff] = useState(false);
 
  const setAsAvailable = async () => {
    setIsSubmitting(true);
    try {
      await UserService.setAsAvailable(appointment.id);
      setTimeout(() => {
        alert("Set day off");
        onHide();
        onAppointmentChange();
      }, 400);
    } catch (error) {
      alert(error.response);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openCloseForm = () => {
    setIsSettingDayOff(!isSettingDayOff);
  }

  const DayOffButton = () => (
    <div style={stylingObject.buttonsDiv}>
      <Button
        style={stylingObject.buttonRight}
        variant="warning"
        type="info"
        onClick={openCloseForm}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <span>Remove Day Off</span>
        )}
      </Button>
    </div>
  );


  const DayOffForm = () => (
    <div style={stylingObject.form}>
      <Button
        type="submit"
        style={stylingObject.buttonCenter}
        disabled={isSubmitting}
        onClick={setAsAvailable}
      >
        {isSubmitting ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <span>Confirm remove Day Off</span>
        )}
      </Button>
    </div>
  );

  return (
    <>
      <DayOffButton />
      {isSettingDayOff && (<DayOffForm/>)}
    </>
  );
};

const stylingObject = {
  form: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    backgroundColor: "#d5d8db",
    borderRadius: 10,
    marginTop: ".4rem",
    marginBottom: "1rem",
  },
  buttonsDiv: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
  },
  buttonCenter: {

  },
  buttonRight: {
    marginLeft: "auto",
  },
};

export default DayOffRemove;
