import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import ClientService from "../services/client.service";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const CancelAppointment = (props) => {
  const { id } = useParams();
  const [statusData, setStatusData] = useState(null);
  const [status, setStatus] = useState("new");
  const [error, setError] = useState(null);
  const [isCanceling, setIsCanceling] = useState(false);

  const ErrorLabel = () => {
    if (error === "wrong status") {
      return <div>Appointment in wrong status, unable to cancel</div>;
    } else if (error === "already used") {
      return <div>Token to cancel appointment was already used</div>;
    } else {
      return <div>Appointment not found, please contact us</div>;
    }
  };

  const Content = () => {
    if (error) {
      return <ErrorLabel />;
    } else if (status === "new") {
      return (
        <div>
          <div>Checking your data, please wait...</div>
          <div>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </div>
        </div>
      );
    } else if (status === "ready") {
      return (
        <div>
          <div style={stylingObject.divs}>Hello {statusData.appointmentClient}</div>
          <div style={stylingObject.divs}>
            Are you sure to cancel your appointment on{" "}
            <b>{moment(statusData.appointmentDate).format("LLL")}</b>?<br/>
            If yes, please click cancel button:
          </div>
          <div style={stylingObject.divs}>
            <Button
              // style={stylingObject.buttonLeft}
              variant="danger"
              type="info"
              onClick={confirmCancel}
              disabled={isCanceling}
            >
              {isCanceling ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Cancel</span>
              )}
            </Button>
          </div>
          <div style={stylingObject.divs}>
            If you would like to reschedule your appointment, please call{" "}
            <a href="tel:+19788950441">(978) 895-0441</a>
          </div>
        </div>
      )
    } else if (status === "canceled") {
      return (
        <div>
          <div>
            You canceled your appointment on{" "}
            <b>{moment(statusData.appointmentDate).format("LLL")}</b>
          </div>
          <div>
            If you would like to make a new appointment, please call{" "}
            <a href="tel:+19788950441">(978) 895-0441</a>
          </div>
        </div>
      );
    }
  };

  const confirmCancel = async () => {
    setIsCanceling(true);
    try {
      const appointmentData = {
        // id: id
      };
      const response = await ClientService.cancelAppointment(
        id,
        appointmentData
      );
      if (response.status === 200) {
        setStatusData(response.data.data);
        setStatus("canceled");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data["errors"]["nonFieldErrors"][0]) {
            setError(error.response.data["errors"]["nonFieldErrors"][0]);
          }
        } else {
          console.log(error.response.data);
        }
      }
    } finally {
      setIsCanceling(false);
    }
  };

  useEffect(() => {
    const confirm = async () => {
      try {
        const appointmentData = {
          // id: id
        };
        const response = await ClientService.checkCancelAppointment(
          id,
          appointmentData
        );
        if (response.status === 200) {
          setStatusData(response.data.data);
          setStatus("ready");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data["errors"]["nonFieldErrors"][0]) {
              setError(error.response.data["errors"]["nonFieldErrors"][0]);
            }
          } else {
            console.log(error.response.data);
          }
        }
      }
    };
    confirm();
  }, [id]);

  return (
    <div className="col-md-12">
      <div style={stylingObject.center}>
        <img
          src="https://lirp.cdn-website.com/a85f75f8/dms3rep/multi/opt/LayoutsDB67747D-C218-0AC4-C1B8-2184CDD25E0F.png_400-356w.png"
          alt="profile-img"
          className="profile-img-card"
          width={200}
        />
        <Content />
      </div>
    </div>
  );
};

const stylingObject = {
  center: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  divs: {
    padding: 5,
  }
};

export default CancelAppointment;
