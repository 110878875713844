import React, { useEffect, useState } from "react";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { Formik, Field } from "formik";
import * as yup from "yup";
import "./styles.module.css";
import ConfirmationBox from "./ConfirmationBox";
import FreeAppointments from "./FreeAppointments";
import UserService from "../services/user.service";
import DayOff from "./DayOff"
import DayOffRemove from "./DayOffRemove"
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber, formatPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input'

const madeList = [
  {
    value: "Aprilla",
    label: "Aprilla",
  },
  {
    value: "BMW",
    label: "BMW",
  },
  {
    value: "Ducati",
    label: "Ducati",
  },
  {
    value: "Harley-Davidson",
    label: "Harley-Davidson",
  },
  {
    value: "Honda",
    label: "Honda",
  },
  {
    value: "Indian",
    label: "Indian",
  },
  {
    value: "Kawasaki",
    label: "Kawasaki",
  },
  {
    value: "KTM",
    label: "KTM",
  },
  {
    value: "Moto Guzzi",
    label: "Moto Guzzi",
  },
  {
    value: "Suzuki",
    label: "Suzuki",
  },
  {
    value: "Triumph",
    label: "Triumph",
  },
  {
    value: "Victory",
    label: "Victory",
  },
  {
    value: "Yamaha",
    label: "Yamaha",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const Appointment = ({ appointment, show, onHide, onAppointmentChange }) => {
  const [clients, setClients] = useState([]);
  const [initialValues, setInitialValues] = useState({
    email: "",
    client_name: "",
    phone_number: "",
    client: "",
    note: "",
    mark: null,
    model: "",
    year: null,
  });
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editDate, setEditDate] = useState(false);
  const [isFreeAppointment, setIsFreeAppointment] = useState(false);
  const statusesAllowedToCancel = ["added", "confirmed", "paid"];
  const validationSchema = yup.object().shape({
    client_name: yup
      .string()
      .required("Client name is required")
      .min(3, ({ min }) => `Client name must be at least ${min} characters`),
    email: yup
      .string()
      .email("Please enter valid e-mail")
      .required("E-mail is required"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .test('', "This not correct phone number", (value) => isValidPhoneNumber(value ? value : "")),
    mark: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable()
      .required("Mark is required"),
    year: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable()
      .required("Motorcycle year is required"),
  });

  const getClients = async () => {
    try {
      const result = await UserService.getClients();
      setClients(result.data.data);
    } catch (error) {
      console.log(error);
      console.log(error.response.data);
      throw error;
    }
  };

  useEffect(() => {
    const getInitialValues = () => {
      const client = appointment.client;
      setInitialValues({
        email: client.email,
        client_name: client.clientName,
        phone_number: client.phoneNumber,
        client: client.clientName,
        note: appointment.note || "",
        model: appointment.model || "",
        mark: {
          value: appointment.mark,
          label: appointment.mark,
        },
        year: {
          value: appointment.year,
          label: appointment.year,
        },
      });
    };

    const isFreeAppointmentValue = appointment && ["draft", "day_off"].includes(appointment.status);

    if(appointment) {
      getClients();
    }
    if (appointment && !isFreeAppointmentValue) {
      getInitialValues();
    } else {
      setInitialValues({
        email: "",
        client_name: "",
        phone_number: "",
        client: "",
        note: "",
        mark: null,
        model: "",
        year: null,
      });
    }
    setIsEditDisabled(appointment && !isFreeAppointmentValue);
    setIsFreeAppointment(isFreeAppointmentValue);
  }, [appointment]);

  const getProductionYears = () => {
    const arrayOfYears = [];
    let year = new Date().getFullYear();
    const month = new Date().getMonth();
    if(month>9) {
      year += 1;
    }
    for (let i = year; i > 1970; i--) {
      arrayOfYears.push({
        value: i,
        label: i,
      });
    }
    return arrayOfYears;
  };
  const productionYears = getProductionYears();

  const formatDate = () => {
    return moment(appointment.startDateTime).format("LLL");
  };

  const submitAppointment = (values, setSubmitting) => {
    if (isFreeAppointment) {
      addAppointmentForClient(values, setSubmitting);
    } else {
      editAppointmentData(values, setSubmitting);
      setIsEditDisabled(true);
    }
  };

  const editAppointmentData = (values, setSubmitting) => {
    setIsSubmitting(true);
    setSubmitting(true);
    const client = appointment.client;
    if (
      client.clientName !== values.client_name ||
      client.email !== values.email ||
      client.phone_number !== values.phone_number
    ) {
      editClient(values);
      // Always edit appointment when client data changed
      editAppointment(values);
    }
    if (
      appointment.note !== values.note ||
      appointment.mark !== values.mark.value ||
      appointment.model !== values.model ||
      appointment.year !== values.year.value
    ) {
      editAppointment(values);
    }
    setSubmitting(false);
    setIsSubmitting(false);
  };

  const editClient = async (values) => {
    try {
      const clientData = {
        clientName: values.client_name,
        email: values.email,
        phone_number: values.phone_number,
      };
      const clientId = appointment.client
        ? appointment.client.id
        : values.client.id;
      const response = await UserService.updateClient(clientId, clientData);
      return response.data.data.id;
    } catch (error) {
      alert(error.response);
    }
  };

  const editAppointment = async (values) => {
    if (appointment.client)
      try {
        const appointmentData = {
          note: values.note,
          mark: values.mark.value,
          model: values.model,
          year: values.year.value,
        };
        await UserService.updateAppointment(appointment.id, appointmentData);
      } catch (error) {
        if (error.response) {
          const txt = JSON.stringify(error.response.data);
          alert(error.response.status, txt);
          console.log(txt);
        }
        console.log(error);
      }
  };

  const addAppointmentForClient = async (values, setSubmitting) => {
    setSubmitting(true);
    try {
      let clientId = null;
      if (values.client) {
        if (
          values.client.clientName !== values.client_name ||
          values.client.email !== values.email ||
          values.client.phone_number !== values.phone_number
        ) {
          editClient(values);
        }
        clientId = values.client.id;
      } else if (appointment.client) {
        clientId = appointment.client.id;
      } else {
        clientId = await addClientToDB(values);
      }

      const appointmentData = {
        clientId: clientId,
        note: values.note,
        mark: values.mark.value,
        model: values.model,
        year: values.year.value,
      };

      await UserService.addAppointment(appointment.id, appointmentData);
      onAppointmentChange();

      setTimeout(() => {
        // alert(JSON.stringify(values, null, 2));
        alert("Appointment set!");
        onHide();
      }, 400);
    } catch (error) {
      if (error.response) {
        const txt = JSON.stringify(error.response.data);
        alert(error.response.status, txt);
        console.log(txt);
      }

      console.log(error);
      // throw error;
    } finally {
      setSubmitting(false);
    }
  };

  const addClientToDB = async (values) => {
    try {
      const clientData = {
        clientName: values.client_name,
        email: values.email,
        phone_number: values.phone_number,
      };
      const response = await UserService.postClient(clientData);
      return response.data.data.id;
    } catch (error) {
      alert(error.response);
      console.log(error.response);
      throw error;
    }
  };

  const askAddPayment = async () => {
    if (
      await ConfirmationBox(
        `Are your sure to confirm payment for client ${
          appointment.client.clientName
        } with appointment at ${formatDate()}?`,
        "OK",
        "Cancel",
        {
          title: "Confirm payment",
        }
      )
    ) {
      addPayment();
    }
  };

  const addPayment = async () => {
    setIsSubmitting(true);
    try {
      await UserService.addPayment(appointment.id);
      onAppointmentChange();
      setTimeout(() => {
        alert("Payment added!");
        onHide();
      }, 400);
    } catch (error) {
      alert(error.response);
    } finally {
      setIsSubmitting(false);
    }
  };

  const askCancelAppointment = async () => {
    if (
      await ConfirmationBox(
        `Are your sure to cancel appointment at ${formatDate()} for client ${
          appointment.client.clientName
        }?`,
        "OK",
        "Cancel",
        {
          title: "Cancel",
        }
      )
    ) {
      cancelAppointment("true");
    }
  };

  const askCancelAppointmentNoRefund = async () => {
    if (
      await ConfirmationBox(
        `Are your sure to cancel appointment WITHOUT REFUND at ${formatDate()} for client ${
          appointment.client.clientName
        }?`,
        "OK",
        "Cancel",
        {
          title: "Cancel",
        }
      )
    ) {
      cancelAppointment("false");
    }
  };

  const cancelAppointment = async (refund) => {
    setIsSubmitting(true);
    try {
      const data = {
        withRefund: refund,
      };
      await UserService.cancelAppointment(appointment.id, data);
      onAppointmentChange();
      setTimeout(() => {
        alert("Appointment canceled!");
        onHide();
      }, 400);
    } catch (error) {
      alert(error.response);
    } finally {
      setIsSubmitting(false);
    }
  };

  const askAppointmentFinished = async () => {
    if (
      await ConfirmationBox(
        `Are your sure to confirm finishing appointment for client ${
          appointment.client.clientName
        }, date ${formatDate()}?`,
        "OK",
        "Cancel",
        {
          title: "Appointment finished!",
        }
      )
    ) {
      appointmentFinished();
    }
  };

  const appointmentFinished = async () => {
    setIsSubmitting(true);
    try {
      await UserService.appointmentComplete(appointment.id);
      onAppointmentChange();
      setTimeout(() => {
        alert("Appointment completed!");
        onHide();
      }, 400);
    } catch (error) {
      alert(error.response);
      console.log(error.response);
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  const askClientDoNotShowUp = async () => {
    if (
      await ConfirmationBox(
        `Are your sure to set up appointment ${formatDate()} as client ${
          appointment.client.clientName
        } do not show up?`,
        "OK",
        "Cancel",
        {
          title: "Client do not show up!",
        }
      )
    ) {
      clientDoNotShowUp();
    }
  };

  const clientDoNotShowUp = async () => {
    setIsSubmitting(true);
    try {
      await UserService.appointmentMissed(appointment.id);
      onAppointmentChange();
      setTimeout(() => {
        alert("Set as client not show up!");
        onHide();
      }, 400);
    } catch (error) {
      alert(error.response);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getStatus = (appointment) => {
    switch (appointment.status) {
      case "draft":
        return "New appointment";
      case "day_off":
        return `This Appointment is set as Day Off because of ${appointment.note}, but you still could add appointment on it`;
      case "added":
        return "Appointment added";
      case "confirmed":
        return "Appointment confirmed";
      case "paid":
        return "Appointment paid";
      case "done":
        return "Appointment done";
      case "client_not_show_up":
        return "Client not show up";
      default:
        return "Appointment";
    }
  };

  const enableEdit = () => {
    setIsEditDisabled(false);
  };

  const enableEditDate = () => {
    setEditDate(true);
  };

  const disableEditDate = (params) => {
    setEditDate(false);
  };

  const newDateChoosen = async (newAppointment) => {
    setIsSubmitting(true);
    setEditDate(false);
    try {
      const data = {
        newAppointment: newAppointment.id,
      };
      await UserService.changeAppointmentDate(appointment.id, data);
      setTimeout(() => {
        alert("Appointment date changed!");
        onHide();
      }, 400);
    } catch (error) {
      alert(error.response);
    } finally {
      setIsSubmitting(false);
    }
  };

  const showButtonWithoutRefund = (appointment) => {
    if (appointment.status !== "paid") {
      return false;
    }
    const start = new Date(appointment.startDateTime);
    const diff = Math.floor((start - Date.now()) / (1000 * 60 * 60 * 48));
    return diff < 1;
  };

  const EditAppointmentButtons = () => {
    if (
      appointment &&
      statusesAllowedToCancel.includes(appointment.status) &&
      isEditDisabled
    ) {
      return (
        <div style={stylingObject.topButtonsDiv}>
          <Button
            style={stylingObject.buttonLeft}
            variant="info"
            type="info"
            onClick={enableEdit}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Edit Client Data</span>
            )}
          </Button>
          <Button
            style={stylingObject.buttonEditDate}
            variant="info"
            type="info"
            onClick={enableEditDate}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Change appointment date</span>
            )}
          </Button>
        </div>
      );
    }
  };

  const getBackgroundColor = (appointment) => {
    if(appointment?.status === "day_off") {
      return "#ffc107"
    }
    else {
      return "#FFF"
    }
  }

  return (
    <>
      <Modal
        // {...props}
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <LoadingIndicator loading={true}/> */}
        <Modal.Header closeButton 
            style={{backgroundColor: getBackgroundColor(appointment)}}>
          <Modal.Title id="contained-modal-title-vcenter"
          >
            {appointment && (
              <h4>
                {getStatus(appointment)}: {formatDate(appointment)}
              </h4>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAppointmentButtons />
          {appointment && appointment.status === "draft" && (
            <DayOff 
              appointment={appointment}
              onAppointmentChange={onAppointmentChange}
              onHide={onHide}
            />
          )}
          {appointment && appointment.status === "day_off" && (
            <DayOffRemove 
              appointment={appointment}
              onAppointmentChange={onAppointmentChange}
              onHide={onHide}
            />
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              submitAppointment(values, setSubmitting);
            }}
            // onChange={}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setValues,
              setFieldValue,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {!isEditDisabled && isFreeAppointment && (
                  <>
                    <h3>Select client</h3>
                    <Field
                      component={Select}
                      options={clients}
                      name="client"
                      placeholder="Select client"
                      onChange={(client) => {
                        setFieldValue("email", client.email);
                        setFieldValue("client_name", client.clientName);
                        setFieldValue("phone_number", client.phoneNumber);
                        setFieldValue("client", client);
                      }}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option.clientName}
                      getOptionValue={(option) => option.id}
                      value={values.client}
                      isSearchable={true}
                      searchBy="clientName"
                      isDisabled={isEditDisabled}
                    />
                    <h3>or add new one</h3>
                  </>
                )}
                {appointment &&
                  appointment.status !== "draft" &&
                  !isEditDisabled && (
                    <Button
                      style={stylingObject.buttonLeft}
                      variant="danger"
                      type="info"
                      onClick={enableEdit}
                    >
                      Save Client Data
                    </Button>
                  )}
                <p>{isEditDisabled}</p>
                <h6 style={stylingObject.labels}>Name</h6>
                <input
                  type="name"
                  name="client_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.client_name}
                  disabled={isEditDisabled}
                />
                <p style={stylingObject.errorLabel}>
                  {errors.client_name &&
                    touched.client_name &&
                    errors.client_name}
                </p>
                <h6 style={stylingObject.labels}>Email</h6>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  disabled={isEditDisabled}
                />
                <p style={stylingObject.errorLabel}>
                  {errors.email && touched.email && errors.email}
                </p>

                <h6 style={stylingObject.labels}>Phone number</h6>
                {isEditDisabled ?
                  <a href={"tel:" + formatPhoneNumber(values.phone_number)} style={stylingObject.phoneNumberLink}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>
                    </svg>
                    {formatPhoneNumber(values.phone_number)}
                  </a>
                :
                  <PhoneInput
                    defaultCountry="US"
                    country="US"
                    type="phone_number"
                    name="phone_number"
                    onChange={(value) => {setFieldValue("phone_number", value ? value : "");}}
                    onBlur={handleBlur}
                    value={values.phone_number}
                    disabled={isEditDisabled}
                  />
                }
                <p style={stylingObject.errorLabel}>
                  {errors.phone_number &&
                    touched.phone_number &&
                    errors.phone_number}
                </p>
                <h6 style={stylingObject.labels}>Select motorcycle year</h6>
                <Field
                  component={Select}
                  options={productionYears}
                  name="year"
                  onChange={(value) => {
                    setFieldValue("year", value);
                  }}
                  onBlur={handleBlur}
                  value={values.year}
                  isDisabled={isEditDisabled}
                />
                <p style={stylingObject.errorLabel}>
                  {errors.year && touched.year && errors.year}
                </p>
                <h6 style={stylingObject.labels}>Select motorcycle made</h6>
                <Field
                  component={Select}
                  options={madeList}
                  name="mark"
                  onChange={(value) => {
                    setFieldValue("mark", value);
                  }}
                  onBlur={handleBlur}
                  value={values.mark}
                  isDisabled={isEditDisabled}
                />
                <p style={stylingObject.errorLabel}>
                  {errors.mark && touched.mark && errors.mark}
                </p>
                <h6 style={stylingObject.labels}>Enter motorcycle model</h6>
                <input
                  // type="name"
                  name="model"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.model}
                  disabled={isEditDisabled}
                />
                <p style={stylingObject.errorLabel}>
                  {errors.model && touched.model && errors.model}
                </p>
                <h6 style={stylingObject.labels}>Notes</h6>
                <input
                  name="note"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.note || ""}
                  disabled={isEditDisabled}
                />
                <p style={stylingObject.errorLabel}>
                  {errors.note && touched.note && errors.note}
                </p>
                <div style={stylingObject.buttonsDiv}>
                  {/* <Button onClick={onHide}>Close</Button> */}
                  {!isEditDisabled && isFreeAppointment && (
                    <Button
                      type="submit"
                      style={stylingObject.buttonRight}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Save and send info to client</span>
                      )}
                    </Button>
                  )}
                  {/* //disabled={isSubmitting} */}
                </div>
              </form>
            )}
          </Formik>
          <div style={stylingObject.buttonsDiv}>
            {appointment &&
              statusesAllowedToCancel.includes(appointment.status) && (
                <div style={stylingObject.cancelButtonsFlex}>
                  <Button
                    type="submit"
                    variant="danger"
                    onClick={askCancelAppointment}
                    color={"red"}
                  >
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <span>
                        Cancel Appointment{" "}
                        {showButtonWithoutRefund(appointment) && (
                          <span>WITH REFUND</span>
                        )}{" "}
                      </span>
                    )}
                  </Button>
                  {showButtonWithoutRefund(appointment) && (
                    <Button
                      type="submit"
                      variant="danger"
                      onClick={askCancelAppointmentNoRefund}
                      color={"red"}
                      style={{ marginTop: 10 }}
                    >
                      {isSubmitting ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Cancel Appointment WITHOUT REFUND</span>
                      )}
                    </Button>
                  )}
                </div>
              )}
            {appointment && appointment.status === "paid" && (
              <Button
                style={stylingObject.buttonRight}
                variant="success"
                type="submit"
                onClick={askAppointmentFinished}
              >
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Service Done</span>
                )}
              </Button>
            )}
            {appointment && appointment.status === "added" && (
              <Button
                style={stylingObject.buttonRight}
                type="submit"
                onClick={askAddPayment}
              >
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Confirm client payment</span>
                )}
              </Button>
            )}
            {appointment && appointment.status === "paid" && (
              <Button
                style={stylingObject.buttonRight}
                variant="dark"
                type="submit"
                onClick={askClientDoNotShowUp}
              >
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Client do not show up</span>
                )}
              </Button>
            )}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
      </Modal.Footer> */}
      </Modal>
      <FreeAppointments
        show={editDate}
        onHide={disableEditDate}
        appointment={appointment}
        newDateChoosen={newDateChoosen}
      />
    </>
  );
};

const stylingObject = {
  topButtonsDiv: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  buttonsDiv: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 10,
    gap: 10,
  },
  labels: {
    paddingTop: ".7rem",
    marginBottom: ".1rem",
  },
  buttonRight: {
    marginLeft: "auto",
  },
  buttonLeft: {
    marginRight: "auto",
  },
  buttonEditDate: {
    marginRight: "auto",
    marginLeft: 10,
  },
  customUI: {
    textAlign: "center",
    color: "white",
    zIndex: 99999,
    position: "fixed",
  },
  errorLabel: {
    color: "red",
  },
  cancelButtonsFlex: {
    display: "flex",
    flexDirection: "column",
  },
  phoneNumberLink: {
    borderWidth: "1px",
    borderStyle: "solid",
    // borderColor: "rgb(200, 200, 200)", rgba(59,59,59,0.3)
    borderRadius: "0.1rem",
    padding: "2px",
    // textAlign: "center",
    textDecoration: "none",
  }
};

export default Appointment;
