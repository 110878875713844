import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import Button from 'react-bootstrap/Button';
// import moment from "moment";
// import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import UserService from "../services/user.service";
import Appointment from "./Appointment";
import UserService from "../services/user.service";

// const statuses = {
//   draft: 'Open',
//   added: 'Added',
//   confirmed: 'Confirmed',
//   paid: 'Paid',
//   done: 'Done',
//   client_not_show_up: 'Client do not show up',
// };

const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
  const backgroundColorGetter = (status) => {
    switch (status) {
      case "draft":
        return "#2D9BF0";
      case "day_off":
        return "#ffc107";
      case "added":
        return "yellow";
      case "confirmed":
        return "red";
      case "paid":
        return "#87C54D";
      case "done":
        return "#10A689";
      case "client_not_show_up":
        return "black";
      default:
        return "white";
    }
  };

  const textColor = (status) => {
    switch (status) {
      case "added":
        return "black";
      case "day_off":
        return "black";
      default:
        return "white";
    }
  };

  var style = {
    backgroundColor: backgroundColorGetter(event.status),
    // borderRadius: '0px',
    // opacity: 0.8,
    color: textColor(event.status),
    // border: '0px',
    // display: 'block'
  };
  return {
    style: style,
  };
};

export default class Home extends Component {
  // const Home = () =>  {
  constructor(props) {
    super(props);
    // const events = generateEvents();

    this.state = {
      content: "",
      appointmentsData: [],
      showAppointment: false,
      currentAppointment: null,
      date: new Date(),
    };
  }

  showModal = (props) => {
    this.setState({ showAppointment: true, currentAppointment: props });
  };

  hideModal = () => {
    this.setState({ showAppointment: false });
  };

  handleSelect = (props) => {
    // prepare new appointment
    props.status = "draft";
    this.setState({ showAppointment: true, currentAppointment: props });
  };

  onAppointmentChanged = () => {
    this.getAppointments();
  };

  componentDidMount() {
    this.getAppointments();
    this.interval = setInterval(() => {
      this.getAppointments();
    }, 15000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getAppointments = async (props) => {
    try {
      const date = props ? props : this.state.date;
      const firstDay = moment(
        new Date(date.getFullYear(), date.getMonth(), -10)
      )
        .toISOString()
        .replace("Z", "");
      const lastDay = moment(
        new Date(date.getFullYear(), date.getMonth() + 1, 10)
      )
        .toISOString()
        .replace("Z", "");
      const result = await UserService.getAppointments(firstDay, lastDay);
      // Ensure state wouldn't change every 15s
      if(JSON.stringify(this.state.appointmentsData) !== JSON.stringify(result.data.data)) {
        this.setState({ appointmentsData: result.data.data });
      }
      return result.data.data;
    } catch (error) {
      if(error.response) {
        console.log(error);
        console.log(error.response.data);
        throw error;

      }
    }
  };

  formatEndDate = (props) => {
    return new Date(props.endDateTime);
  };

  formatStartDate = (props) => {
    return new Date(props.startDateTime);
  };

  formatTitle = (appointment) => {
    const hour = moment(appointment.startDateTime).format("h a");
    if (appointment.status === "draft") {
      return `${hour}: available`;
    } else if (appointment.status === "day_off") {
      return `${hour}: ${appointment.note}`;
    } else {
      return `${hour}: ${appointment.client.clientName}`;
    }
  };

  viewChanged = (props) => {
    this.setState({ date: props });
    this.getAppointments(props);
  };

  render() {
    return (
      <>
        <div className="container">
          <header className="jumbotron">
            <h3>{this.state.content}</h3>
          </header>
          <h2>{this.state.date.toDateString()}</h2>
          <Calendar
            views={["month", "agenda"]}
            selectable
            localizer={localizer}
            date={this.state.date}
            defaultView="month"
            events={this.state.appointmentsData}
            eventPropGetter={eventStyleGetter}
            style={{ height: "100vh" }}
            onSelectEvent={this.showModal}
            onNavigate={this.viewChanged}
            // onSelectSlot={this.handleSelect}
            startAccessor={this.formatStartDate}
            endAccessor={this.formatEndDate}
            titleAccessor={this.formatTitle}
          />
        </div>
        {/* <Button variant="primary" onClick={generateEvents}>
          Launch vertically centered modal
        </Button> */}
        <Appointment
          show={this.state.showAppointment}
          onHide={this.hideModal}
          appointment={this.state.currentAppointment}
          onAppointmentChange={this.onAppointmentChanged}
        />
      </>
    );
  }
}
