import apiAuth from "./apiAuth";
import authHeader from "./auth-header";

// const API_URL = "http://patryk85.chickenkiller.com:31014/api/v1.0.0/";
// const API_URL = "http://localhost:8000/api/v1.0.0/";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://robsdyno.malejkoit.com/api/v1.0.0/"
    : "http://localhost:8000/api/v1.0.0/";

class UserService {

  updateAppointment(id, appointmentData) {
    return apiAuth.patch(API_URL + `appointments/${id}/`, appointmentData, {
      headers: authHeader(),
    });
  }

  addAppointment(id, appointmentData) {
    return apiAuth.patch(API_URL + `appointments/${id}/add/`, appointmentData, {
      headers: authHeader(),
    });
  }

  addPayment(id) {
    return apiAuth.patch(
      API_URL + `appointments/${id}/deposit/`,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  cancelAppointment(id, data) {
    return apiAuth.patch(API_URL + `appointments/${id}/cancel/`, data, {
      headers: authHeader(),
    });
  }

  changeAppointmentDate(id, data) {
    return apiAuth.patch(API_URL + `appointments/${id}/move/`, data, {
      headers: authHeader(),
    });
  }

  appointmentComplete(id) {
    return apiAuth.patch(
      API_URL + `appointments/${id}/complete/`,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  appointmentMissed(id) {
    return apiAuth.patch(
      API_URL + `appointments/${id}/missed/`,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  setDayOff(id, data) {
    return apiAuth.patch(
      API_URL + `appointments/${id}/day_off/`,
      data,
      {
        headers: authHeader(),
      }
    );
  }

  setAsAvailable(id) {
    return apiAuth.patch(
      API_URL + `appointments/${id}/day_available/`,
      {},
      {
        headers: authHeader(),
      }
    );
  }

  postAppointment(appointmentData) {
    return apiAuth.post(API_URL + "appointments/", appointmentData, {
      headers: authHeader(),
    });
  }

  getAppointments(firstDay, lastDay, status=null) {
    var url = API_URL + `appointments/?from=${firstDay}&to=${lastDay}`
    if(status) {
      url += `&status=${status}`
    }
    return apiAuth.get(url, {
      headers: authHeader(),
    });
  }

  getFreeAppointments() {
    return apiAuth.get(API_URL + `appointments/?status=draft&limit=2000`, {
      headers: authHeader(),
    });
  }

  getAppointmentsByClient(client) {
    return apiAuth.get(
      API_URL + `appointments/?search=client&client=${client}&limit=100`,
      {
        headers: authHeader(),
      }
    );
  }

  postClient(clientData) {
    return apiAuth.post(API_URL + "clients/", clientData, {
      headers: authHeader(),
    });
  }

  updateClient(id, clientData) {
    return apiAuth.patch(API_URL + `clients/${id}/`, clientData, {
      headers: authHeader(),
    });
  }

  getClients() {
    return apiAuth.get(API_URL + "clients/", { headers: authHeader() });
  }
}

export default new UserService();
