import React, { Component } from "react";
import { Routes, Route, Navigate, Link } from "react-router-dom";
import { Outlet } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/Login";
// import Register from "./components/register.component";
import Home from "./components/Home";
import AppointmentsList from "./components/AppointmentsList";
import AddAppointments from "./components/AddAppointments";
import ClientConfirmation from "./components/ClientConfirmation";
import CancelAppointment from "./components/CancelAppointment";
// import Profile from "./components/profile.component";
// import BoardUser from "./components/board-user.component";
// import BoardModerator from "./components/board-moderator.component";

const NavBar = ({ currentUser, logOut }) => {
  return (
    <nav className="navbar navbar-expand navbar-dark bg-dark">
      <Link to={"/"} className="navbar-brand">
        <img
          alt=""
          src="https://lirp.cdn-website.com/a85f75f8/dms3rep/multi/opt/LayoutsDB67747D-C218-0AC4-C1B8-2184CDD25E0F.png_400-356w.png"
          width={70}
        />
      </Link>
      {currentUser ? (
        <div className="navbar-nav ml-auto">
          <li className="nav-item">
            <a href="/home" className="nav-link">
              Calendar
            </a>
          </li>
          {/* <li className="nav-item">
            <a href="/add" className="nav-link">
              Add Appointments
            </a>
          </li> */}
          <li className="nav-item">
            <a href="/list" className="nav-link">
              Appt
            </a>
          </li>
          <li className="nav-item" style={{ position: "absolute", right: 10 }}>
            <a href="/login" className="nav-link" onClick={logOut}>
              LogOut
            </a>
          </li>
        </div>
      ) : (
        <div className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link to={"/login"} className="nav-link">
              Login
            </Link>
          </li>
        </div>
      )}
    </nav>
  );
};

const OurOutlet = () => {
  return (
    <div className="container mt-3">
      <Outlet />
    </div>
  );
};

const WithNavbar = ({ currentUser, logOut }) => {
  return (
    <>
      <NavBar currentUser={currentUser} logOut={logOut} />
      <OurOutlet />
    </>
  );
};

const WithoutNavbar = () => {
  return (
    <>
      <OurOutlet />
    </>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div>
        {/* //   <NavBar currentUser={currentUser}/> */}

        {/* <div className="container mt-3"> */}
        <Routes>
          <Route element={<WithoutNavbar />}>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/confirm/:id" element={<ClientConfirmation />} />
            <Route exact path="/cancel/:id" element={<CancelAppointment />} />
          </Route>

          <Route
            element={
              <WithNavbar currentUser={currentUser} logOut={this.logOut} />
            }
          >
            <Route
              exact
              path="/"
              element={
                currentUser ? (
                  <Navigate to="/home" replace={true} />
                ) : (
                  <Navigate to="/login" replace={true} />
                )
              }
            />
            <Route path="/home" element={<Home />} />
            <Route path="/add" element={<AddAppointments />} />
            <Route path="/list" element={<AppointmentsList />} />
          </Route>
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/register" element={<Register />} /> */}
          {/* <Route path="/profile" element={<Profile />} /> */}
          {/* <Route path="/user" element={<BoardUser />} /> */}
          {/* <Route path="/mod" element={<BoardModerator />} /> */}
          {/* <Route path="/admin" element={<BoardAdmin />} /> */}
        </Routes>
        {/* </div> */}
      </div>
    );
  }
}

export default App;
