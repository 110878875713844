import React, { Component } from "react";
import moment from "moment";
import Button from "react-bootstrap/Button";
// import moment from "moment";
// import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import UserService from "../services/user.service";
import UserService from "../services/user.service";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const postAppointments = async (appointments) => {
  for (const appointment of appointments) {
    const appointmentData = {
      start_date_time: appointment.start,
      end_date_time: appointment.end,
    };
    try {
      await UserService.postAppointment(appointmentData);
    } catch (error) {
      console.log(error);
      console.log(error.response.data);
      throw error;
    }
  }
};

export default class Home extends Component {
  // const Home = () =>  {
  constructor(props) {
    super(props);
    // const events = generateEvents();

    this.state = {
      content: "",
      appointmentsData: [],
      showAppointment: false,
      currentAppointment: null,
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  showModal = (props) => {
    this.setState({ showAppointment: true, currentAppointment: props });
  };

  hideModal = () => {
    this.setState({ showAppointment: false });
  };

  handleSelect = (props) => {
    this.setState({ showAppointment: true, currentAppointment: props });
  };

  appointmentChanged = () => {
    this.getAppointments();
  };

  componentDidMount() {
    // this.getAppointments();
    // UserService.getPublicContent().then(
    //   response => {
    //     this.setState({
    //       content: response.data
    //     });
    //   },
    //   error => {
    //     this.setState({
    //       content:
    //         (error.response && error.response.data) ||
    //         error.message ||
    //         error.toString()
    //     });
    //   }
    // );
  }

  getAppointments = async () => {
    try {
      const result = await UserService.getAppointments();
      this.setState({ appointmentsData: result.data.data });
      return result.data.data;
    } catch (error) {
      console.log(error);
      console.log(error.response.data);
      throw error;
    }
  };

  formatEndDate = (props) => {
    return new Date(props.endDateTime);
  };

  formatStartDate = (props) => {
    return new Date(props.startDateTime);
  };

  formatTitle = (appointment) => {
    const hour = moment(appointment.startDateTime).format("h a");
    if (appointment.status === "draft") {
      return `${hour}: available`;
    } else {
      // return "xxx";
      return `${hour}: ${appointment.client.clientName}`;
    }
  };

  setStartDate = (date) => {
    this.setState({ startDate: date });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date });
  };

  generateEvents = () => {
    var endDate = new Date();
    endDate.setDate(endDate.getDate() + 30);
    var eventsData = [];
    for (
      var ourDate = this.state.startDate;
      ourDate <= this.state.endDate;
      ourDate.setDate(ourDate.getDate() + 1)
    ) {
      // remove Sundays
      if (ourDate.getDay() === 0) {
        continue;
      }
      const appointmentStartDate = new Date(ourDate.setHours(9, 0, 0));
      const appointmentEndDate = new Date(ourDate.setHours(12, 0, 0));
      const appointment2StartDate = new Date(ourDate.setHours(13, 0, 0));
      const appointment2EndDate = new Date(ourDate.setHours(16, 0, 0));
      eventsData.push(
        {
          start: appointmentStartDate,
          end: appointmentEndDate,
        },
        {
          start: appointment2StartDate,
          end: appointment2EndDate,
        }
      );
    }
    postAppointments(eventsData);
    return eventsData;
  };

  render() {
    return (
      <>
        <div className="container">
          <header className="jumbotron">
            <h3>{this.state.content}</h3>
          </header>
        </div>
        <div>
          Start date:
          <DatePicker
            selected={this.state.startDate}
            onChange={this.setStartDate}
          />
        </div>
        <div>
          End date:
          <DatePicker
            selected={this.state.endDate}
            onChange={(date) => this.setEndDate(date)}
          />
        </div>
        <div>
          <Button variant="primary" onClick={this.generateEvents}>
            Add new appointments
          </Button>
        </div>
      </>
    );
  }
}
