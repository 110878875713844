import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import ConfirmationBox from "./ConfirmationBox";
import UserService from "../services/user.service";

const localizer = momentLocalizer(moment);

const FreeAppointments = ({ appointment, show, onHide, newDateChoosen }) => {
  const [freeAppointments, setFreeAppointments] = useState([]);
  const getFreeAppointments = async () => {
    try {
      const result = await UserService.getFreeAppointments();
      setFreeAppointments(result.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("LLL");
  };

  const askChangeAppointmentDate = async (newAppointment) => {
    console.log(newAppointment);
    if (
      await ConfirmationBox(
        `Client: ${appointment.client.clientName}\nFrom: ${formatDate(
          appointment.startDateTime
        )}\nTo: ${formatDate(newAppointment.startDateTime)}`,
        "OK",
        "Cancel",
        {
          title: "Are your sure to change appointment?",
        }
      )
    ) {
      newDateChoosen(newAppointment);
    }
  };

  useEffect(() => {
    if (show) {
      getFreeAppointments();
    }
  }, [show]);

  const formatTitle = (appointment) => {
    const hour = moment(appointment.startDateTime).format("h a");
    if (appointment.status === "draft") {
      return `${hour}: available`;
    } else {
      return `${hour}: ${appointment.client.clientName}`;
    }
  };

  const formatEndDate = (props) => {
    return new Date(props.endDateTime);
  };

  const formatStartDate = (props) => {
    return new Date(props.startDateTime);
  };

  return (
    <Modal
      // {...props}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select new data
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Calendar
          views={["month", "week", "agenda"]}
          selectable
          localizer={localizer}
          // date={this.state.date}
          defaultView="month"
          events={freeAppointments}
          // eventPropGetter={eventStyleGetter}
          style={{ height: "100vh" }}
          onSelectEvent={askChangeAppointmentDate}
          // onNavigate={this.viewChanged}
          // onSelectSlot={this.handleSelect}
          startAccessor={formatStartDate}
          endAccessor={formatEndDate}
          titleAccessor={formatTitle}
        />
      </Modal.Body>
    </Modal>
  );
};

export default FreeAppointments;
